<template>
	<div class="successModalBody">
		<div class="closeWrp">
			<span class="ic-icclose" @click="$emit('close')"></span>
		</div>
		<div class="greenBgTxt">
			<span class="ic-check"></span>
			<div class="txt">{{ $t("form.success.h") }}</div>
		</div>
		<div class="txtWrp">
			<div class="t1">
				{{ $t("form.success.txt") }}
			</div>
			<div class="schedule">{{ $t("form.success.schedule") }}</div>
			<router-link
				:to="{
					name: 'MainPage',
					params: { locale: locale == 'uk' ? null : locale },
				}"
				class="lnk"
				>{{ $t("form.success.btn") }}</router-link
			>
		</div>
	</div>
</template>

<script>
export default {
	name: "FormSuccess",
};
</script>

<style lang="scss" scoped>
.successModalBody {
	width: 100vw;
	max-width: 450px;
	background-color: #fff;
	.closeWrp {
		display: flex;
		justify-content: flex-end;
		.ic-icclose {
			font-size: 18px;
			padding: 9px;
			color: $cgray4;
			cursor: pointer;
		}
	}
	.greenBgTxt {
		background-color: $csuccess;
		text-align: center;
		color: #fff;
		padding: 10px;
		.ic-check {
			font-size: 20px;
		}
		.txt {
			@include t2l;
			color: #fff;
		}
	}
	.txtWrp {
		padding: 15px 40px 30px;
		.t1 {
			@include t3l;
		}
		.schedule {
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 160%;
			letter-spacing: -0.025em;
			color: #6cc49f;
		}
		.lnk {
			display: block;
			background-color: $cgray6;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 120%;
			text-align: center;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #111325;
			padding: 20px;
			text-decoration: none;
			margin-top: 40px;
		}
	}
}
</style>