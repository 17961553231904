<template>
	<transition name="modalfade" appear>
		<div class="modalbg" :key="'s1'">
			<div class="scroll">
				<transition name="updown" appear>
					<slot @close="$emit('close')" @accept="$emit('accept')"></slot>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "ModalBase",
	created() {
		document.body.classList.toggle("noScroll", true);
	},
	destroyed() {
		document.body.classList.toggle("noScroll", false);
	},
};
</script>

<style lang="scss">
.modalfade-enter-active,
.modalfade-leave-active {
	transition: opacity 0.3s;
}
.modalfade-enter, .modalfade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
	opacity: 0;
}
.updown-enter-active,
.updown-leave-active {
	transition: 0.3s;
}
.updown-enter, .updown-leave-to /* .fade-leave-active до версии 2.1.8 */ {
	transform: translate(0, -100px);
	opacity: 0;
}
.modalbg {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(65, 70, 98, 0.34);
	display: flex;
	justify-content: center;
	align-items: center;
	.scroll{
		max-width: 100vw;
		max-height: 100vh;
		overflow: auto;
		padding: 10px 0;
	}
}
</style>