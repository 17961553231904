<template>
	<div class="radioWrp">
		<div class="itemsWrp" :class="{ row: onrow }">
			<span class="labelTxt">{{ placeholder }}</span>
			<div v-for="(item, index) in items" :key="index">
				<div class="item" @click="onItemSelect(index, item.extra)">
					<span class="deco" :class="{ active: selectedIndex == index }"></span>
					{{ item.label || item }}
					<slide-up-down
						:active="selectedIndex == index && item.extra == 'date'"
					>
						<div
							style="padding-top: 20px"
							v-if="selectedIndex == index && item.extra == 'date'"
						>
							<date-picker-field
								:placeholder="$t('Date_of_death')"
								:showError="showError"
								@change="onDateInput"
								:validate="validateExtra"
								propname="extra"
								:prevalue="extra"
							></date-picker-field>
						</div>
					</slide-up-down>
				</div>
			</div>
		</div>
		<div class="errorTip" v-if="showError && validate.$invalid">
			{{ $t("fieldErrors.selectRadio") }}
		</div>
	</div>
</template>

<script>
import DatePickerField from "./DatePickerField.vue";
export default {
	name: "RadioInput",
	components: {
		DatePickerField,
	},
	props: {
		showError: {
			type: Boolean,
			default: true,
		},
		items: {
			type: Array,
			default: [],
		},
		placeholder: {
			type: String,
			default: "",
		},
		propname: {
			type: String,
			default: "",
		},
		prevalue: {
			type: String,
			default: null,
		},
		prevalueExtra: {
			type: [Date, String],
			default: null,
		},
		validate: {
			type: Object,
		},
		validateExtra: {
			type: Object,
		},
		onrow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedIndex: null,
			extra: null,
		};
	},
	methods: {
		onItemSelect(index, extra) {
			this.selectedIndex = index;
			this.$emit("change", {
				name: this.propname,
				value: this.items[index].label || this.items[index],
				haveExtra: extra ? true : false,
			});
		},
		onDateInput(e) {
			this.$emit("change", {
				extra: true,
				name: this.propname,
				value: e.value,
			});
		},
	},
	mounted() {
		setTimeout(() => {
			let r = this.items.findIndex(
				(item) => item == this.prevalue || item.label == this.prevalue
			);
			if (r >= 0) {
				this.selectedIndex = r;
			}
			if (this.prevalueExtra) {
				this.extra = this.prevalueExtra;
			}
		}, 200);
	},
};
</script>

<style lang="scss" scoped>
.radioWrp {
	margin: 40px 0 20px;
	.itemsWrp {
		box-shadow: 0 0 0 1px inset #e9ebf4;
		display: grid;
		grid-template-columns: 1fr 1fr;
		position: relative;
		&.row {
			grid-template-columns: 1fr;
		}
		@media (max-width: 687px) {
			display: block;
		}
	}
	.item {
		padding: 20px;
		cursor: pointer;
		position: relative;
		padding-left: 55px;
		@media (max-width: $tm) {
			padding: 10px 20px;
			padding-left: 55px;
		}
		.deco {
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			box-shadow: 0 0 0 1px #111325;
			position: relative;
			vertical-align: bottom;
			margin-right: 8px;
			position: absolute;
			top: 20px;
			left: 22px;
			@media (max-width: $tm) {
				top: 10px;
			}
			&:before {
				content: "";
				width: 12px;
				height: 12px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0);
				background-color: #111325;
				transition: 0.3s ease;
			}
			&.active::before {
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
	.labelTxt {
		position: absolute;
		top: -20px;
		left: 15px;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 130%;
		letter-spacing: 0.02em;
		color: #585b6d;
		@media (max-width: $tm) {
			top: unset;
			bottom: 100%;
		}
	}
	.errorTip {
		@include t4;
		color: $cerror;
		margin-top: 3px;
	}
}
</style>
