<template>
    <div>
        <label :for="`input${_uid}`" class="dateComponent" ref="spn">
            <the-mask
                :id="`input${_uid}`"
                :placeholder="placeholder"
                class="date"
                :mask="masks"
                v-model.trim="value"
                :masked="true"
                @click.native="onFocus"
            />
            <span class="ic-calendar" @click="onPictogramClick"></span>
            <date-select
                v-if="dateShown"
                class="date_select"
                :focusDate="focusDate"
                :initialDate="initialDate"
                @select="onDpChange"
            ></date-select>
        </label>
        <div class="tip" v-if="showError && validate.$invalid">
            {{ $t("fieldErrors.selectDate") }}
        </div>
    </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import DateSelect from "./calendar/DateSelect.vue";
export default {
    components: { DateSelect, TheMask },
    name: "DatePicker",
    props: {
        placeholder: {
            type: String,
            default: "placeholder"
        },
        showError: {
            type: Boolean,
            default: true
        },
        propname: {
            type: [String, Object],
            default: ""
        },
        prevalue: {
            type: [Date, String],
            default: null
        },
        validate: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            dateShown: false,
            value: "",
            initialDate: null,
            focusDate: new Date(),

            mobTop: null
        };
    },
    watch: {
        value(newValue) {
            let s = newValue.split(".");
            if (s && s.length == 3 && s[2].length == 4) {
                let dateStr = `${s[1]}.${s[0]}.${s[2]}`;
                let d = new Date(dateStr);
                if (Object.prototype.toString.call(d) === "[object Date]") {
                    if (isNaN(d.getTime())) {
                        this.onChange("invalid date");
                    } else {
                        this.initialDate = d;
                        this.focusDate = d;
                        this.onChange(d);
                    }
                }
            } else if (newValue == "") {
                this.onChange(null);
            } else {
                this.onChange("invalid date");
            }
        }
    },
    computed: {
        masks() {
            return ["##.##.####"];
        }
    },
    methods: {
        onPictogramClick() {
            setTimeout(() => {
                this.dateShown = !this.dateShown;
            }, 100);
        },
        onScroll() {
            if (this.dateShown) {
                this.mobTop = this.$refs.spn.getBoundingClientRect().top + 60;
            }
        },
        onFocus() {
            if (window.innerWidth <= 600) {
                this.mobTop = this.$refs.spn.getBoundingClientRect().top + 60;
            }
            this.dateShown = true;
            document.body.addEventListener("click", this.onClick);
        },
        onClick(e) {
            let trgt = e.target;
            if (
                !(
                    trgt.classList.contains("dateComponent") ||
                    trgt.closest(".dateComponent")
                )
            ) {
                this.dateShown = false;
                document.body.removeEventListener("click", this.onClick);
            }
        },
        onChange(e) {
            this.$emit("change", { name: this.propname, value: e });
        },
        onDpChange(e) {
            this.value = `${Intl.DateTimeFormat("uk", {
                day: "2-digit"
            }).format(new Date(e))}.${Intl.DateTimeFormat("uk", {
                month: "numeric",
                minimumIntegerDigits: 3
            }).format(new Date(e))}.${Intl.DateTimeFormat("uk", {
                year: "numeric"
            }).format(new Date(e))}`;
        }
    },
    destroyed() {
        document.removeEventListener("scroll", this.onScroll);
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
        if (this.prevalue) {
            this.value = `${Intl.DateTimeFormat(this.$i18n.locale, {
                day: "2-digit"
            }).format(this.prevalue)}.${Intl.DateTimeFormat(this.$i18n.locale, {
                month: "numeric",
                minimumIntegerDigits: 3
            }).format(this.prevalue)}.${Intl.DateTimeFormat(this.$i18n.locale, {
                year: "numeric"
            }).format(this.prevalue)}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.dateComponent {
    height: 50px;
    background-color: #fff;
    box-shadow: 0 0 0 1px inset #e9ebf4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    position: relative;
    .date {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 0.01em;
        color: #111325;
        cursor: pointer;
        outline: none;
        border: none;
    }
    .ic-calendar {
        cursor: pointer;
        font-size: 20px;
        color: $cgray4;
    }
    .date_select {
        position: absolute;
        top: 100%;
        z-index: 100;
        left: 0;
        @media (max-width: $ts) {
            left: 0;
            right: 0;
            width: 100%;
        }
    }
    .tip {
        @include t3;
        color: $cred1;
        margin-top: 3px;
    }
}
</style>
