<template>
	<div
		class="day"
		:class="{
			selected: isSelected,
			current: isCurrentDay,
			disabled: isDisabled,
		}"
		@click="$emit('click', date)"
	>
		<span>{{ dateTxt }}</span>
	</div>
</template>

<script>
export default {
	name: "DayCell",
	props: ["date", "selectedDate"],
	computed: {
		dateTxt() {
			return this.date.getDate();
		},
		isSelected() {
			return (
				Intl.DateTimeFormat("uk", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				}).format(new Date(this.date)) ==
				Intl.DateTimeFormat("uk", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				}).format(new Date(this.selectedDate))
			);
		},
		isDisabled() {
			let a = new Date();
			// return (
			// 	this.date <
			// 	new Date(`${a.getMonth() + 1}.${a.getDate()}.${a.getFullYear()}`)
			// );
			return false;
		},
		isCurrentDay() {
			return (
				Intl.DateTimeFormat("uk", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				}).format(new Date(this.date)) ==
				Intl.DateTimeFormat("uk", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				}).format(new Date())
			);
		},
	},
	methods: {},
};
</script>

<style lang="scss">
.day {
	position: relative;
	height: 49px;
	display: flex;
	justify-content: center;
	align-items: center;
	&:before {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 0;
		transform: translate(-50%, -50%);
		cursor: pointer;
		transition: 0.2s ease;
	}
	&.selected {
		color: $cwhite;
		&:before {
			background: $cred1 !important;
		}
	}
	&.disabled {
		pointer-events: none;
		color: #aeaeb2;
	}
	span {
		position: relative;
		z-index: 2;
		cursor: pointer;
	}
	// &:hover,
	&.current {
		&:before {
			// box-shadow: 0 0 0 1px inset $cred1;
			background-color: $cgray6;
		}
	}
}
</style>