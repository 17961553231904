<template>
	<label
		:for="`input${_uid}`"
		class="mainInpputWrap"
		:class="{ isError: isError, isFocused: isFocused }"
	>
		<div class="phoneInputWrap">
			<div class="label">{{ placeholder }}</div>
			<div
				class="phonePicker"
				@click="onCountrySelect"
				:class="{ active: dropDownShown }"
				ref="phonePicker"
			>
				<img :src="currentFlagImage" :alt="currentFlagImage" />
				<span class="ic-icexpand-down"></span>
			</div>
			<the-mask
				:placeholder="computedPlaceholder"
				:id="`input${_uid}`"
				:mask="currentMaskArray"
				:masked="true"
				v-model.trim="value"
				@keyup.native="onKeyUp"
				@focus.native="onFocus"
				@blur.native="isFocused = false"
			/>
			<div class="selectList" v-show="dropDownShown">
				<div
					class="item"
					v-for="(item, index) in phoneMasks"
					:key="index"
					@click="onMaskClick(index)"
				>
					<img :src="item.image" :alt="item.country" />
					<div>{{ item.text }}</div>
				</div>
			</div>
		</div>
		<span class="tip" v-if="isError">{{ isError }}</span>
	</label>
</template>

<script>
import { TheMask } from "vue-the-mask";

export default {
	name: "PhoneInput",
	components: {
		TheMask,
	},
	props: {
		placeholder: {},
		itype: {},
		errors: {},
		propname: {},
		validate: {},
		showError: {},
		prevalue: {},
		lengthname: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			value: "",
			isFocused: false,
			dropDownShown: false,
			currentMask: 0,
			phoneMasks: [
				{
					image: require("@/assets/img/phoneFlags/ua.svg"),
					country: "UA",
					text: "Ukraine (Україна) +380",
					masks: ["+380 ## ### ## ##"],
				},
				{
					image: require("@/assets/img/phoneFlags/Uganda.svg"),
					country: "UG",
					text: "Uganda +256",
					masks: ["+256 ## ######"],
				},
				{
					image: require("@/assets/img/phoneFlags/Russia.svg"),
					country: "RU",
					text: "Russia (Росiя) +7",
					masks: ["+7 ### ### ## ##"],
				},
			],
		};
	},
	mounted() {
		var resultStr = "";
		setTimeout(() => {
			if (this.prevalue) {
				setTimeout(() => {
					this.value = this.prevalue;
					let p = this.prevalue;
					let afterSpace = false;
					for (let index = 0; index < p.length; index++) {
						if (!afterSpace) {
							if (p[index] == " ") afterSpace = true;
							resultStr = resultStr + p[index];
						} else {
							if (p[index].match(/\d/)) {
								resultStr = resultStr + "#";
							} else {
								resultStr = resultStr + p[index];
							}
						}
					}
					let res = this.phoneMasks.findIndex((item) => {
						let a = item.masks.find((mask) => {
							if (mask == resultStr) return true;
						});
						if (a) return true;
					});
					this.currentMask = res;
					this.value = this.prevalue;
					this.onKeyUp();
				}, 200);
			} else {
				this.value = "";
				this.onKeyUp();
			}
		}, 200);
	},
	methods: {
		onFocus() {
			this.isFocused = true;
			if (this.value == "") {
				this.value = " ";
				this.onKeyUp();
			}
		},
		onKeyUp() {
			let obj = {};
			if (this.lengthname) {
				obj = {
					name: this.propname,
					value: this.value,
					length: this.currentMaskLength,
					lengthname: this.lengthname,
				};
			} else {
				obj = {
					name: this.propname,
					value: this.value,
					length: this.currentMaskLength,
				};
			}
			this.$emit("change", obj);
		},
		onMaskClick(index) {
			this.currentMask = index;
			this.dropDownShown = false;
			this.value = " ";
		},
		onCountrySelect() {
			this.dropDownShown = !this.dropDownShown;
			if (this.dropDownShown) {
				document.addEventListener("mousedown", this.clickHandler);
				let a = this.$refs.phonePicker.getBoundingClientRect();
				console.log(a);
			} else {
				document.removeEventListener("mousedown", this.clickHandler);
			}
		},
		clickHandler(e) {
			if (
				!(
					e.target.classList.contains("selectList") ||
					e.target.closest(".selectList")
				)
			) {
				this.dropDownShown = false;
				document.removeEventListener("mousedown", this.clickHandler);
			}
		},
	},
	computed: {
		computedPlaceholder() {
			let m = this.phoneMasks[this.currentMask].masks[0];
			return m.replace(/#|\s/g, "");
		},
		currentMaskArray() {
			return JSON.parse(
				JSON.stringify(
					this.currentMask != -1 ? this.phoneMasks[this.currentMask].masks : []
				)
			);
		},
		currentFlagImage() {
			return this.currentMask != -1
				? this.phoneMasks[this.currentMask].image
				: "noImg";
		},
		currentMaskLength() {
			if (this.currentMask != -1) {
				let mask =
					"################################################################################";
				this.phoneMasks[this.currentMask].masks.forEach((item) => {
					if (item.length < mask.length) mask = item;
				});
				return mask.length;
			}
			return 0;
		},
		isMaskedInput() {
			if (this.mask !== undefined && this.mask !== null && this.mask !== "")
				return true;
			else return false;
		},
		isError() {
			if (this.validate.$invalid && this.showError) {
				if (this.validate.required === false) {
					return this.$t("fieldErrors.required");
				}
				if (this.validate.minLength === false) {
					return this.$t("fieldErrors.minLength", {
						length: this.validate.$params.minLength.min,
					});
				}
				if (this.validate.email === false) {
					return this.$t("fieldErrors.email");
				}
				return "some error";
			} else return null;
		},
	},
};
</script>

<style lang='scss' scoped>
.mainInpputWrap {
	display: block;
	margin: 40px 0 20px;
	.phoneInputWrap {
		// box-shadow: 0 0 0 1px inset #8e8e93;
		display: flex;
		position: relative;
		.label {
			position: absolute;
			@include t4;
			color: $cblacktxt;
			top: -21px;
			left: 109px;
			@media (max-width: $tm) {
				top: unset;
				bottom: 100%;
			}
		}
		.phonePicker {
			width: 84px;
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0 0 0 1px inset #e9ebf4;
			transition: 0.3s ease;
			background: #fff;
			img {
				width: 25px;
			}
			.ic-triangle-down {
				font-size: 6px;
				margin-left: 16px;
				color: #aeaeb2;
			}
			&.active {
				.ic-triangle-down {
					transform: rotate(180deg);
				}
			}
		}
		input {
			height: 64px;
			padding: 0 24px;
			width: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #000000;
			border: none;
			box-sizing: border-box;
			outline: none;
			background: #fff;

			transition: 0.3s ease;
			box-shadow: 0 0 0 1px inset #e9ebf4;
			margin-left: 10px;
		}
		.selectList {
			position: absolute;
			z-index: 5;
			bottom: -1px;
			left: 0;
			right: 0;
			transform: translate(0, 100%);
			background: #ffffff;
			box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
			max-height: 310px;
			overflow-y: auto;
			padding: 8px 0;
			.item {
				display: flex;
				align-items: center;
				padding: 16px;
				transition: 0.3s ease;
				cursor: pointer;
				&:hover {
					background-color: #f2f2f2;
				}
				img {
					margin-right: 8px;
					width: 25px;
				}
				div {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					color: #000000;
				}
			}
		}
	}
	&.isFocused {
		.phoneInputWrap {
			// box-shadow: 0 0 0 2px inset #000;
			.phonePicker {
				box-shadow: 0px 0 0 2px inset $cgray5;
			}
			input {
				box-shadow: 0px 0 0 2px inset $cgray5;
			}
		}
	}
	&.isError {
		.phoneInputWrap {
			.phonePicker {
				box-shadow: 0px 0 0 2px inset $cerror;
			}
			input {
				box-shadow: 0px 0 0 2px inset $cerror;
				color: $cerror;
			}
		}
	}
	&.isError.isFocused {
		.phoneInputWrap {
			.phonePicker {
				box-shadow: 0px 0 0 2px inset $cerror;
			}
			input {
				box-shadow: 0px 0 0 2px inset $cerror;
				color: $cerror;
			}
		}
	}
	.tip {
		@include t4;
		display: block;
		color: $cerror;
		margin: 4px 16px;
	}
}
</style>