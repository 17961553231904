const naslidkiExtraValidator = (value, vm) => {
    let a = vm.naslidkiItems.find(
        (item) => item == vm.naslidki || item.label == vm.naslidki
    );
    if (typeof a == "string") return true;
    else {
        return value != null && typeof value == "object";
    }
};
const dateValidator = (value) => {
    if (typeof value == "object" || value == null || value == '') {
        return true;
    } else {
        return false;
    }
};
const timeValidator = (value) => {
    if (value) {
        let r = value.split(":");
        if (r.length == 2) {
            if (r[1] != "" && +r[0] <= 23 && +r[1] <= 59) {
                return true;
            } else {
                return false;
            }
        } else return false;
    } else return true;
};
const categoriesPrExtraValidator = (value, vm) => {
    let a = vm.categoriesPrItems.find(
        (item) => item == vm.categoriesPr || item.label == vm.categoriesPr
    );
    if (typeof a == "string") return true;
    else {
        return typeof value == "object";
    }
};

export {
    naslidkiExtraValidator,
    dateValidator,
    timeValidator,
    categoriesPrExtraValidator,
}
