<template>
	<div>
		<label :for="`input${_uid}`" class="timeComponent">
			<the-mask
				:id="`input${_uid}`"
				:placeholder="placeholder"
				class="time"
				:mask="masks"
				v-model.trim="value"
				:masked="true"
			/>
			<span class="ic-clock"></span>
		</label>
		<div class="tip" v-if="showError && validate.$invalid">
			{{ $t("fieldErrors.selectTime") }}
		</div>
	</div>
</template>

<script>
import { TheMask } from "vue-the-mask";
export default {
	name: "TimePicker",
	components: {
		TheMask,
	},
	props: {
		placeholder: {
			type: String,
			default: "placeholder",
		},
		showError: {
			type: Boolean,
			default: true,
		},
		propname: {
			type: String,
			default: "",
		},
		prevalue: {
			type: [String],
			default: null,
		},
		validate: {
			type: Object,
		},
	},
	data() {
		return {
			dateShown: false,
			value: "",
			initialDate: null,
			focusDate: new Date(),
		};
	},
	watch: {
		value(newValue) {
			this.$emit("change", { name: this.propname, value: newValue });
		},
	},
	computed: {
		masks() {
			return ["##:##"];
		},
	},
	mounted() {
		if (this.prevalue) {
			this.value = this.prevalue;
		}
	},
};
</script>

<style lang="scss" scoped>
.timeComponent {
	height: 50px;
	background-color: #fff;
	box-shadow: 0 0 0 1px inset #e9ebf4;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	cursor: pointer;
	.time {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		letter-spacing: 0.01em;
		color: #111325;
		cursor: pointer;
		outline: none;
		border: none;
		width: 100px;
	}
	.ic-clock {
		font-size: 20px;
		color: $cgray4;
	}
}
</style>