<template>
	<div class="inputComponent">
		<label :for="`input${_uid}`" :class="{ isError: isError, active: isFocus }">
			<template v-if="!mask">
				<input
					:id="`input${_uid}`"
					:type="itype ? itype : 'text'"
					v-model.trim="value"
					@keyup="onKeyUp"
					:name="propname"
					@focus="onFocus"
					@blur="onBlur"
				/>
			</template>
			<template v-else>
				<the-mask
					:id="`input${_uid}`"
					:mask="mask ? mask : ''"
					:masked="true"
					v-model.trim="value"
					@keyup.native="onKeyUp"
				/>
			</template>
			<span class="labeltxt" :class="{ active: value != '' || isFocus }">{{
				placeholder
			}}</span>
		</label>
		<span class="tip" v-if="isError">{{ isError }}</span>
	</div>
</template>

<script>
import defaultinput from "@/components/Inputs/DefaultInputMixin.js";
export default {
	name: "DefaultInput",
	mixins: [defaultinput],
};
</script>

<style lang='scss'>
.inputComponent {
	margin: 40px 0 20px;
	border: unset;

	label {
		box-shadow: 0 0 0 1px inset #e9ebf4;
		background-color: #fff;
		position: relative;
		display: block;
		box-sizing: border-box;
		transition: 0.3s ease;
		&.active {
			box-shadow: 0 0 0 2px inset #d3d6e7;
		}
	}
	input {
		height: 58px;
		width: 100%;
		border: none;
		background-color: transparent;
		outline: none;
		padding-left: 15px;

		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		letter-spacing: 0.01em;
		color: #111325;

		&::placeholder {
			color: $cblacktxt;
		}
	}
	.isError {
		box-shadow: 0 0 0 2px inset $cerror;
	}
	.labeltxt {
		position: absolute;
		top: 50%;
		left: 20px;
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		letter-spacing: 0.01em;
		color: #111325;
		transform: translate(0, -50%);
		transition: 0.1s ease;
		&.active {
			top: -20px;
			left: 15px;
			transform: unset;
			@media (max-width: $tm) {
				top: unset;
				bottom: 100%;
			}

			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 130%;
			letter-spacing: 0.02em;
			color: #585b6d;
		}
	}
}
.tip {
	@include t4;
	color: $cerror;
	margin: 4px 16px;
}
</style>